import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCategoryId } from '../../redux/slices/categorySlice';

function Categories() {
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    // Отправить GET-запрос на сервер для получения списка категорий
    fetch('https://server.puskateli.by/categories')
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => console.error('Error fetching categories', error));
  }, []);
  const dispatch = useDispatch();
  const categoryId = useSelector((state) => state.category.categoryId);

  return (
    <div className="categories">
      <ul>
        {categories.map((name) => (
          <li
            key={name.id}
            onClick={() => dispatch(setCategoryId(name.id))}
            className={categoryId === name.id ? 'active' : ''}>
            {name.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Categories;
