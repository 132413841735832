import React from 'react';
import axios from 'axios';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, setCategoryId } from '../redux/slices/categorySlice';

import Categories from '../Components/Categories';
import ProductBlock from '../Components/ProductBlock';
import Skeleton from '../Components/ProductBlock/Skeleton';
// import Pagination from '../Components/Pagination';
import Search from '../Components/Search';

const Home = () => {
  const dispatch = useDispatch();
  const [items, setItems] = React.useState([]);
  const [isLoad, setisLoad] = React.useState(true);
  const currentPage = useSelector((state) => state.category.pageCount);

  const categoryId = useSelector((state) => state.category.categoryId);
  const searchValue = useSelector((state) => state.category.searchValue);
  const isSearch = React.useRef(false);

  // React.useEffect(() => {
  //   if (window.location.search) {
  //     const params = qs.parse(window.location.search.substring(1));
  //     console.log(params);
  //     dispatch(setFilters(params));
  //     dispatch(setCategoryId(1));
  //     isSearch.current = true;
  //   }
  // }, []);

  const fetchProduct = () => {
    setisLoad(true);
    axios
      .get(
        `https://server.puskateli.by/items?categoryId=${
          categoryId === 1 ? '' : categoryId
        }&currentPage=${currentPage + 1}${searchValue === '' ? '' : '&search=' + searchValue}`,
      )
      .then((res) => {
        setItems(res.data);
        setisLoad(false);
      });
  };

  React.useEffect(() => {
    if (!isSearch.current) {
      fetchProduct();
    }
    isSearch.current = false;
  }, [categoryId, searchValue, currentPage]);

  const products = items
    .filter((obj) => {
      if (obj.title.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
      console.log(obj);
      return false;
    })
    .map((obj) => <ProductBlock key={obj.id} {...obj} />);
  const skeletons = [...new Array(6)].map((_, index) => <Skeleton key={index} />);

  return (
    <div className="container">
      <div className="content__top">
        <Categories />
        <Search />
      </div>
      <h2 className="content__title">Все товары</h2>
      <div className="content__items">{isLoad ? skeletons : products}</div>
      {/* <Pagination /> */}
    </div>
  );
};

export default Home;
