import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function UpdateEmail() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    // Здесь делаем GET-запрос для получения текущего email из сервера
    const fetchEmail = async () => {
      try {
        const response = await axios.get('https://server.puskateli.by/get-email');
        if (response.data.success) {
          setEmail(response.data.email);
        } else {
          setMessage({ text: response.data.message, color: 'red' });
        }
      } catch (error) {
        console.error('Ошибка при получении email:', error);
        setMessage({ text: 'Ошибка при получении email', color: 'red' });
      }
    };

    fetchEmail();
  }, []); // [] означает, что этот эффект будет выполняться только один раз при загрузке компонента

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put('https://server.puskateli.by/update-email', { email });
      if (response.data.success) {
        setMessage({ text: 'Email успешно изменен', color: 'green' });
      } else {
        setMessage({ text: response.data.message, color: 'red' });
      }
    } catch (error) {
      console.error('Ошибка при изменении email:', error);
      setMessage({ text: 'Ошибка при изменении email', color: 'red' });
    }
  };

  return (
    <div>
      <h2>Изменение email:</h2>
      <div>
        <label>Новый email:</label>
        <input type="text" value={email} onChange={handleChange} />
      </div>
      <div>
        <button className="button" onClick={handleUpdate}>
          Сохранить
        </button>
      </div>
      {message && <p style={{ color: message.color }}>{message.text}</p>}
    </div>
  );
}
