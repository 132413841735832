import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Message = ({ message, messageType }) => {
  const messageStyles = {
    success: { color: 'green' },
    error: { color: 'red' },
    warning: { color: 'orange' },
  };

  return (
    <div style={{ ...messageStyles[messageType], display: message ? 'block' : 'none' }}>
      {message}
    </div>
  );
};

const DeleteCategory = ({ categories, onDeleteCategory }) => {
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [choose, setChoose] = useState(false);

  const [message, setMessage] = useState(''); // Сообщение для вывода
  const [messageType, setMessageType] = useState(''); // Тип сообщения (success, error, warning)

  useEffect(() => {
    // Очищаем сообщение через 5 секунд
    const timeout = setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [message]);

  const handleCategoryChange = (event) => {
    if (event.target.value === 'Все') {
      setMessage("Нельзя удалять категорию 'Все'");
      setMessageType('warning');
      return;
    }
    const selectedCategoryName = event.target.value;
    setSelectedCategoryName(selectedCategoryName);
    setChoose(true);
  };

  const handleDelete = async () => {
    // Ищем категорию с выбранным именем
    const selectedCategory = categories.find((category) => category.name === selectedCategoryName);

    if (!selectedCategory) {
      setMessage('Выбранная категория не найдена');
      setMessageType('error');
      return;
    }

    try {
      await axios.delete(`https://server.puskateli.by/categories/${selectedCategory.id}`);
      setMessage('Категория успешно удалена');
      setMessageType('success');
      setChoose(false);
      setSelectedCategoryName('');
    } catch (error) {
      // Обработка ошибок
      setMessage('Ошибка при удалении категории');
      setMessageType('error');
      console.error('Ошибка при удалении категории:', error);
    }
  };

  return (
    <>
      <h2>Удалить категорию</h2>
      <Message message={message} messageType={messageType} />
      <div>
        <label>Выберите категорию для удаления:</label>
        <select value={selectedCategoryName} onChange={handleCategoryChange}>
          <option value="">Выберите категорию</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      {choose && (
        <>
          <button type="submit" onClick={handleDelete}>
            Удалить
          </button>
        </>
      )}
    </>
  );
};

export default DeleteCategory;
