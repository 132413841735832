import React from 'react';
import ContentLoader from 'react-content-loader';

const ProductSkeleton = () => (
  <ContentLoader
    speed={2}
    width={325}
    height={550}
    viewBox="0 0 325 550"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <rect x="26" y="12" rx="0" ry="0" width="293" height="296" />
    <rect x="177" y="252" rx="0" ry="0" width="10" height="1" />
    <rect x="22" y="323" rx="0" ry="0" width="298" height="30" />
    <rect x="24" y="361" rx="0" ry="0" width="295" height="30" />
    <rect x="27" y="428" rx="0" ry="0" width="117" height="38" />
    <rect x="169" y="430" rx="0" ry="0" width="131" height="37" />
  </ContentLoader>
  // <ContentLoader
  //   className="puskatel-block"
  //   speed={2}
  //   width={289}
  //   height={467}
  //   viewBox="0 0 289 467"
  //   backgroundColor="#f3f3f3"
  //   foregroundColor="#ecebeb">
  //   <rect x="4" y="281" rx="10" ry="10" width="280" height="27" />
  //   <rect x="7" y="318" rx="10" ry="10" width="280" height="66" />
  //   <rect x="14" y="402" rx="10" ry="10" width="107" height="24" />
  //   <rect x="153" y="396" rx="10" ry="10" width="124" height="36" />
  //   <rect x="10" y="11" rx="0" ry="0" width="260" height="260" />
  // </ContentLoader>
);

export default ProductSkeleton;
