import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Message = ({ message, messageType }) => {
  const messageStyles = {
    success: { color: 'green' },
    error: { color: 'red' },
    warning: { color: 'orange' },
  };

  return (
    <div style={{ ...messageStyles[messageType], display: message ? 'block' : 'none' }}>
      {message}
    </div>
  );
};

export default function AddCategory() {
  const [newCategoryName, setNewCategoryName] = useState('');
  const [message, setMessage] = useState(''); // Сообщение для вывода
  const [messageType, setMessageType] = useState(''); // Тип сообщения (success, error, warning)

  const checkCategoryExists = async (name) => {
    try {
      const response = await axios.get(`https://server.puskateli.by/categories/check?name=${name}`);
      return response.data.length > 0;
    } catch (error) {
      console.error('Error checking category existence:', error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!newCategoryName) {
      setMessage('Название категории не может быть пустым');
      setMessageType('warning');
      return;
    }

    const categoryExists = await checkCategoryExists(newCategoryName);

    if (categoryExists) {
      setMessage('Категория с таким именем уже существует');
      setMessageType('warning');
      return;
    }

    try {
      const response = await axios.post('https://server.puskateli.by/categories', {
        name: newCategoryName,
        isactive: 1,
      });
      setMessage('Новая категория создана успешно');
      setNewCategoryName('');
      setMessageType('success');
    } catch (error) {
      // Обработка ошибок
      setMessage('Ошибка при создании категории');
      setMessageType('error');
      console.error('Ошибка при создании категории:', error);
    }
  };

  useEffect(() => {
    // Очищаем сообщение через 5 секунд
    const timeout = setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [message]);

  return (
    <>
      <h2>Создать новую категорию</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Название категории"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <button type="submit">Создать</button>
        <Message message={message} messageType={messageType} />
      </form>
    </>
  );
}
