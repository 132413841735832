import React from 'react';
import ContactForm from '../Components/ContactForm';
const Cart = () => {
  return (
    <div className="container container--cart">
      <div className="cart">
        <ContactForm />
      </div>
    </div>
  );
};

export default Cart;
