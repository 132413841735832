import React from 'react';
import axios from 'axios';
import ListAssortiment from './ListAssortiment';
import styles from './ManageAssortiment.module.scss';
import AddAssortiment from './AddAssortiment';
import SelectAndEditAssortiment from './EditAssortiment/SelectAndEdit';
import DeleteAssortiment from './DeleteAssortiment';

export default function ManageAssortiment() {
  const [assortiment, setAssortiment] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get('https://server.puskateli.by/assortiment')
      .then((response) => {
        setAssortiment(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Ошибка при получении ассортимента товаров:', error);
        setLoading(false);
      });
  }, []);

  const [indexCategory, setIndexCategory] = React.useState(1);

  return (
    <div className={styles.upload_form}>
      <div className={styles.categories}>
        <button className="button button--add" onClick={() => setIndexCategory(1)}>
          Список всего ассортимента
        </button>
        {/* <button className="button" onClick={() => setIndexCategory(1)}>
          Правка ассортимента не доступна
        </button> */}
        {/* <button className="button" onClick={() => setIndexCategory(3)}>
          Изменить
        </button> */}
        {/* <button className="button" onClick={() => setIndexCategory(4)}>
          Удалить
        </button> */}
      </div>
      <div>
        {indexCategory === 1 && (
          <ListAssortiment
            assortiment={assortiment.data}
            loading={loading}
            setAssortiment={setAssortiment}
            setLoading={setLoading}
          />
        )}
        {indexCategory === 2 && (
          <AddAssortiment assortiment={assortiment.data} setAssortiment={setAssortiment} />
        )}
        {indexCategory === 3 && (
          <SelectAndEditAssortiment
            assortiments={assortiment.data}
            setAssortiments={setAssortiment}
          />
        )}
        {indexCategory === 4 && (
          <DeleteAssortiment assortment={assortiment.data} onDeleteAssortment={setAssortiment} />
        )}
      </div>
    </div>
  );
}
