import React from 'react';
import Popup from '../Popup';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addItem } from '../../redux/slices/cartSlice';

function ProductBlock({ title, imageUrl, types, characterPDF, id }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const onClickCalling = () => {
    const item = {
      title,
    };
    dispatch(addItem(item));
  };

  const srcImg = require(`../../Assets/${imageUrl}`);
  console.log(srcImg);
  const pdfLink = `https://server.puskateli.by/${characterPDF}`;

  return (
    <div className="puskatel-block-wrapper">
      <div className="puskatel-block">
        <img className="puskatel-block__image" src={srcImg} alt={`Изображение: ${title}`} />
        <h4 className="puskatel-block__title">{title}</h4>
        <div className="puskatel-block__bottom">
          <div className="puskatel-block__price">
            <button
              className="button button--outline button--puskatel"
              onClick={() => setOpen(!open)}>
              <span>Ассортимент</span>
            </button>
            {open && (
              <div className="product__popup--allwindow" onClick={() => setOpen(!open)}>
                <div className="product__popup" onClick={(e) => e.stopPropagation()}>
                  <Popup title={title} id={id} onClose={setOpen} onAdmin={false} />
                </div>
              </div>
            )}
          </div>
          <a
            href={pdfLink}
            className="button button--outline button--puskatel"
            target="_blank"
            rel="noreferrer">
            <span>Характеристика</span>
          </a>
        </div>
        <Link to="/contact" onClick={onClickCalling}>
          <button className="button button--outline button--add button--download">
            <span>Заказать звонок</span>
          </button>
        </Link>
      </div>
    </div>
  );
}

export default ProductBlock;
