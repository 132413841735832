import React from 'react';
import ListCategories from './ListCategories';
import AddCategory from './AddCategory';
import styles from './ManageCategories.module.scss';
import UpdateCategory from './UpdateCategory';
import DeleteCategory from './DeleteCategory';
import axios from 'axios';

export default function ManageCategories() {
  const [categories, setCategories] = React.useState([]);
  const [update, setUpdate] = React.useState(true);

  React.useEffect(() => {
    // Отправить GET-запрос на сервер для получения списка категорий
    axios
      .get('https://server.puskateli.by/categories')
      .then((response) => {
        // Разбираем ответ и устанавливаем категории в состояние
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories', error);
      });
  }, [update]);

  const [indexCategory, setIndexCategory] = React.useState(1);

  const UpdateAndsetIndexCategory = (id) => {
    setIndexCategory(id);
    setUpdate(!update);
  };

  return (
    <div className={styles.upload_form}>
      <div className={styles.categories}>
        <button className="button button--add" onClick={() => UpdateAndsetIndexCategory(1)}>
          Список категорий
        </button>
        <button className="button" onClick={() => setIndexCategory(2)}>
          Добавить категорию
        </button>
        <button className="button" onClick={() => setIndexCategory(3)}>
          Изменить категорию
        </button>
        <button className="button" onClick={() => setIndexCategory(4)}>
          Удалить категорию
        </button>
      </div>
      <div>{indexCategory === 1 && <ListCategories categories={categories} />}</div>
      <div>{indexCategory === 2 && <AddCategory />}</div>
      <div>
        {indexCategory === 3 && (
          <UpdateCategory categories={categories} onUpdateCategory={setCategories} />
        )}
      </div>
      <div>
        {indexCategory === 4 && (
          <DeleteCategory categories={categories} onDeleteCategory={setCategories} />
        )}
      </div>
    </div>
  );
}
