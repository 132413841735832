import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function UpdateAppName() {
  const [nameapp, setNameApp] = useState('');
  const [message, setMessage] = useState(null);

  // Загрузка текущего значения имени приложения при монтировании компонента
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('https://server.puskateli.by/get-app-name'); // Здесь укажите правильный URL для вашего GET-запроса
        if (response.data.success) {
          setNameApp(response.data.data.nameapp);
        } else {
          console.error('Ошибка при получении имени приложения:', response.data.message);
        }
      } catch (error) {
        console.error('Ошибка при получении имени приложения:', error);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    setNameApp(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put('https://server.puskateli.by/update-app-name', { nameapp });
      if (response.data.success) {
        setMessage({ text: 'Имя приложения успешно изменено', color: 'green' });
      } else {
        setMessage({ text: response.data.message, color: 'red' });
      }
    } catch (error) {
      console.error('Ошибка при изменении имени приложения:', error);
      setMessage({ text: 'Ошибка при изменении имени приложения', color: 'red' });
    }
  };

  return (
    <div>
      <h2>Изменение имени приложения:</h2>
      <div>
        <label>Новое имя приложения:</label>
        <input type="text" value={nameapp} onChange={handleChange} />
      </div>
      <div>
        <button className="button" onClick={handleUpdate}>
          Сохранить
        </button>
      </div>
      {message && <p style={{ color: message.color }}>{message.text}</p>}
    </div>
  );
}
