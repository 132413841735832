import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
};

// {
//   id: '',
//   image: '',
//   title: '',
//   subtitle: '',
//   assortiments: [],
// },
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.products.push(action.payload);
    },
    clearItemById: (state, action) => {
      const idToRemove = action.payload;
      state.products = state.products.filter((product) => product.id !== idToRemove);
    },
    clearItemsAll: (state) => {
      state.products = [];
    },
  },
});

export const { addItem, clearItemById, clearItemsAll } = cartSlice.actions;
export default cartSlice.reducer;
