import React from 'react';
import logoSvg from '../Assets/img/logo.svg';
import { Link, useLocation } from 'react-router-dom';
import Corsina from './Corsina';
import axios from 'axios';
import ReactModal from 'react-modal';

function Header() {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isContactPage = location.pathname === '/contact';
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    // Загрузка текущего телефона с сервера при монтировании компонента
    axios
      .get('https://server.puskateli.by/get-phone')
      .then((response) => {
        if (response.data.success) {
          setPhone(response.data.phone);
        } else {
          console.error('Ошибка при загрузке телефона:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке телефона:', error);
      });
  }, []);

  React.useEffect(() => {
    // Здесь делаем GET-запрос для получения текущего email из сервера
    const fetchEmail = async () => {
      try {
        const response = await axios.get('https://server.puskateli.by/get-email');
        if (response.data.success) {
          setEmail(response.data.email);
        }
      } catch (error) {
        console.error('Ошибка при получении email:', error);
      }
    };

    fetchEmail();
  }, []);

  const handleViewPDF = async () => {
    openModal();
  };
  const handleDownloadPDF = async () => {
    try {
      // Просто перенаправляем пользователя на URL для скачивания PDF файла
      window.location.href = 'https://server.puskateli.by/download-pdf';
    } catch (error) {
      console.error('Ошибка при скачивании PDF файла:', error);
    }
    closeModal(); // Закрыть модальное окно после скачивания
  };

  const tel = 'tel:' + phone.replace(/[^+\d]/g, '');
  const mailto = ' mailto:' + email;
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="header__logo">
            <Link to="/">
              <img className="header__logo-image" src={logoSvg} alt="ОАО Электроаппаратура" />
            </Link>
            <div>
              <Link to="/">
                <h1>ОАО "Электроаппаратура"</h1>
              </Link>
              <p>
                <a href={tel}>{phone}</a>
              </p>
              <p>
                <a href={mailto}>{email}</a>
              </p>
            </div>
          </div>

          <button className="button button--cart button--catalog" onClick={handleViewPDF}>
            <span>Каталог</span>
          </button>
          {!isContactPage && <Corsina />}
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Подтверждение скачивания"
        className="modal-content" // Добавьте класс для стилизации контента модального окна
      >
        <div className="modal-header">
          <h2>Подтверждение скачивания</h2>
        </div>
        <div className="modal-body">
          <p>Вы уверены, что хотите скачать каталог?</p>
        </div>
        <div className="modal-buttons">
          <button className="modal-button" onClick={handleDownloadPDF}>
            Скачать
          </button>
          <button className="modal-button" onClick={closeModal}>
            Отмена
          </button>
        </div>
      </ReactModal>
    </>
  );
}

export default Header;
