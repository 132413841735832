import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import styles from './SecuritySettings.module.scss';

const SecuritySettings = ({ nameUser, userId }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setErrorMessage('Заполните все поля');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage('Новый пароль и подтверждение не совпадают');
      return;
    }

    try {
      const response = await axios.post('https://server.puskateli.by/change-password', {
        userId: userId,
        currentPassword: currentPassword,
        newPassword: newPassword,
      });

      if (response.data.success) {
        setSuccessMessage('Пароль успешно изменен');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setErrorMessage('');
      } else {
        setErrorMessage('Ошибка при изменении пароля');
      }
    } catch (error) {
      setErrorMessage('Ошибка при изменении пароля');
    }
  };

  return (
    <div className={styles.securitySettings}>
      <h3>Настройки безопасности (смена пароля для {nameUser})</h3>
      <div>
        <label>Текущий пароль:</label>
        <input
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </div>
      <div>
        <label>Новый пароль:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div>
        <label>Подтвердите новый пароль:</label>
        <input
          type="password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
      </div>
      <button className={styles.changePasswordButton} onClick={handleChangePassword}>
        Изменить пароль
      </button>{' '}
      {/* Используем класс из стилей */}
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}{' '}
      {/* Используем класс из стилей */}
      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}{' '}
      {/* Используем класс из стилей */}
    </div>
  );
};

export default SecuritySettings;
