import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './DeleteProduct.module.scss';

const DeleteProduct = () => {
  const [selectedProductId, setSelectedProductId] = useState('');
  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    // Загрузка списка товаров при загрузке компонента
    axios
      .get('https://server.puskateli.by/items-admin')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  const handleProductChange = (e) => {
    const productId = e.target.value;
    setSelectedProductId(productId);
  };

  const handleDelete = async () => {
    // Проверяем, выбран ли товар для удаления
    if (!selectedProductId) {
      setMessage('Пожалуйста, выберите товар для удаления.');
      setMessageType('error');
      return;
    }

    try {
      // Отправляем запрос на удаление товара по ID
      await axios.delete(`https://server.puskateli.by/items/${selectedProductId}`);
      setMessage('Товар успешно удален');
      setMessageType('success');
    } catch (error) {
      console.error('Ошибка при удалении товара:', error);
      setMessage('Не удалось удалить товар');
      setMessageType('error');
    }

    // Обновляем список товаров после удаления
    axios
      .get('https://server.puskateli.by/items')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  };

  return (
    <div className={styles.upload_form}>
      <h2>Удаление товара</h2>
      <select onChange={handleProductChange} value={selectedProductId}>
        <option value="">Выберите товар для удаления</option>
        {products.map((product) => (
          <option key={product.id} value={product.id}>
            {product.title}
          </option>
        ))}
      </select>
      <button onClick={handleDelete}>Удалить товар</button>
      {message && <div className={messageType}>{message}</div>}
    </div>
  );
};

export default DeleteProduct;
