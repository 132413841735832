import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Message = ({ message, messageType }) => {
  const messageStyles = {
    success: { color: 'green' },
    error: { color: 'red' },
    warning: { color: 'orange' },
  };

  return (
    <div style={{ ...messageStyles[messageType], display: message ? 'block' : 'none' }}>
      {message}
    </div>
  );
};

const DeleteAssortment = ({ assortment, onDeleteAssortment }) => {
  const [selectedItemId, setSelectedItemId] = useState('');
  const [choose, setChoose] = useState(false);

  const [message, setMessage] = useState(''); // Сообщение для вывода
  const [messageType, setMessageType] = useState(''); // Тип сообщения (success, error, warning)

  useEffect(() => {
    // Очищаем сообщение через 5 секунд
    const timeout = setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [message]);

  const handleItemChange = (event) => {
    const selectedItemId = event.target.value;
    setSelectedItemId(selectedItemId);
    setChoose(true);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    if (!selectedItemId) {
      setMessage('Выберите товар для удаления');
      setMessageType('warning');
      return;
    }

    try {
      await axios.delete(`https://server.puskateli.by/delete-assortiment/${selectedItemId}`);
      setMessage('Товар успешно удален');
      setMessageType('success');
      setChoose(false);
      setSelectedItemId('');
      // Вызов onDeleteAssortment, чтобы обновить с
    } catch (error) {
      // Обработка ошибок
      setMessage('Ошибка при удалении товара');
      setMessageType('error');
      console.error('Ошибка при удалении товара:', error);
    }
  };

  return (
    <>
      <h2>Удалить товар</h2>
      <Message message={message} messageType={messageType} />
      <div>
        <label>Выберите товар для удаления:</label>
        <select value={selectedItemId} onChange={handleItemChange}>
          <option value="">Выберите товар</option>
          {assortment.map((item) => (
            <option key={item.id} value={item.id}>
              {item.namenklaturoboznach}
            </option>
          ))}
        </select>
      </div>
      {choose && (
        <>
          <button type="submit" onClick={handleDelete}>
            Удалить
          </button>
        </>
      )}
    </>
  );
};

export default DeleteAssortment;
