import React, { useState } from 'react';
import styles from './LoginBlock.module.scss';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate из react-router-dom
import { useSelector, useDispatch } from 'react-redux';
import { setUserId, setRole } from '../../redux/slices/authSlice';

export default function Login() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const role = useSelector((state) => state.auth.role);
  const [login, setLogin] = useState(''); // Заменяем email на login
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Используем useNavigate для перенаправления

  const handleLoginChange = (e) => {
    setLogin(e.target.value); // Обновляем состояние login
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    const response = await axios.post('https://server.puskateli.by/login', {
      login: login,
      password: password,
    });

    const token = response.data.token;
    // Установка куки и дожидаемся её установки
    await new Promise((resolve) => {
      Cookies.set('token', token, { expires: 30 });
      resolve();
    });

    if (token) {
      fetch('https://server.puskateli.by/check-token', {
        method: 'GET',
        headers: {
          Auth: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.userId) {
            dispatch(setUserId(data.userId));
            navigate('/admin');
          } else {
            Cookies.remove('token');
          }
        })
        .catch((error) => {
          console.error('Ошибка при проверке токена:', error);
        });
    }
  };

  return (
    <div className={styles.root}>
      <h1>Введите Ваши учетные данные</h1>
      <div>
        <input type="text" value={login} onChange={handleLoginChange} placeholder="Login" />
      </div>
      <div>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Пароль"
        />{' '}
      </div>
      <button className="button button--outline" onClick={handleLogin}>
        Войти
      </button>
    </div>
  );
}
