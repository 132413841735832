import React from 'react';
import styles from './SettingsApp.module.scss';
import axios from 'axios';
import UpdatePhone from './UpdatePhone';
import UpdateEmail from './UpdateEmail';
import UpdateAppName from './UpdateAppName';
import UpdatePDF from './UpdatePDF';

export default function SettingsApp() {
  const [indexCategory, setIndexCategory] = React.useState(1);
  return (
    <div className={styles.upload_form}>
      <div className={styles.categories}>
        <button className="button button--add" onClick={() => setIndexCategory(1)}>
          Изменение телефона
        </button>
        <button className="button" onClick={() => setIndexCategory(2)}>
          Изменение email
        </button>
        {/* <button className="button" onClick={() => setIndexCategory(3)}>
          Изменение ОАО
        </button> */}
        <button className="button" onClick={() => setIndexCategory(4)}>
          Обновить PDF каталог
        </button>
      </div>
      <div>{indexCategory === 1 && <UpdatePhone />}</div>
      <div>{indexCategory === 2 && <UpdateEmail />}</div>
      <div>{indexCategory === 3 && <UpdateAppName />}</div>
      <div>{indexCategory === 4 && <UpdatePDF />}</div>
    </div>
  );
}
