import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './AddProduct.module.scss';

export default function AddProduct() {
  const [formData, setFormData] = useState({
    title: '',
    characterPDF: '',
    isActive: true,
    image: null,
    pdf: null,
  });

  const [categories, setCategories] = useState([]); // Список категорий
  const [selectedCategory, setSelectedCategory] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios
      .get('https://server.puskateli.by/categories')
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file,
    });
  };

  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      pdf: file,
    });
  };

  const hideSuccessMessage = () => {
    setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNaN(parseInt(selectedCategory, 10))) {
      setErrorMessage('Пожалуйста, выберите категорию');
      return;
    }

    const productData = new FormData();
    for (const key in formData) {
      productData.append(key, formData[key]);
    }

    productData.append('categoryID', selectedCategory);

    try {
      const response = await axios.post('https://server.puskateli.by/insertProduct', productData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });

      setFormData({
        ...formData,
        categoryID: selectedCategory,
        image: response.data.imageUrl,
        characterPDF: response.data.pdfUrl,
      });

      setSuccessMessage(formData.title + ' успешно добавлен! Можно уходить со страницы!');

      setTimeout(() => {
        setFormData({
          title: '',
          characterPDF: '',
          isActive: true,
          image: null,
          pdf: null,
        });
        setUploadProgress(0);
        setSuccessMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error uploading product:', error);
      setErrorMessage(`Failed to upload product: ${error.message}`);
    }
  };

  return (
    <div className={styles.upload_form}>
      <h2>Добавление товара</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Название:</label>
          <input type="text" name="title" value={formData.title} onChange={handleInputChange} />
        </div>
        <div>
          <label>Категория</label>
          <select
            name="categoryID"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Выберите категорию</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Выберите изображение:</label>
          <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
        </div>
        <div>
          <label>Выберите PDF характеристики:</label>
          <input type="file" name="pdf" accept=".pdf" onChange={handlePdfChange} />
        </div>
        <div>
          <label>Отображать (да - галочка установлена)</label>
          <input
            type="checkbox"
            name="isActive"
            checked={formData.isActive}
            onChange={() =>
              setFormData({
                ...formData,
                isActive: !formData.isActive,
              })
            }
          />
        </div>
        <div>
          <button type="submit">Добавить</button>
        </div>
      </form>

      {uploadProgress > 0 && (
        <div>
          <p>Прогресс загрузки: {uploadProgress}%</p>
          <progress max="100" value={uploadProgress} />
        </div>
      )}

      {successMessage && (
        <div className={styles.success_message}>
          <p>{successMessage}</p>
        </div>
      )}

      {errorMessage && (
        <div className={styles.error_message}>
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}
