import React from 'react';
import styles from './ListProduct.module.scss';
import linksvg from './icons/link.svg';
import Popup from '../../../Popup';

const ListProduct = ({ products }) => {
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [selectedPTitle, setSelectedPTitle] = React.useState(null);

  const openPopup = (productId, title) => {
    setSelectedProductId(productId);
    setSelectedPTitle(title);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setSelectedProductId(null);
    setPopupOpen(false);
  };

  return (
    <div>
      <h2>Список товаров</h2>
      <table className={styles['product-table']}>
        <thead>
          <tr>
            <th className={styles['table-header']}>Название</th>
            <th className={styles['table-header']}>Категория</th>
            <th className={styles['table-header']}>Файл PDF</th>
            <th className={styles['table-header']}>Изображение</th>
            <th className={styles['table-header']}>Ассортимент</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td className={styles['table-cell']}>{product.title}</td>
              <td className={styles['table-cell']}>{product.categoryName}</td>
              <td className={styles['table-cell']}>
                <a
                  className={styles['a-cell']}
                  href={`https://server.puskateli.by/${product.characterPDF}`} // Замените на правильный путь к PDF
                  target="_blank"
                  rel="noreferrer">
                  Просмотр PDF
                </a>
              </td>
              <td className={styles['table-cell']}>
                <a
                  className={styles['a-cell']}
                  href={`https://server.puskateli.by/${product.imageUrl}`} // Замените на правильный путь к изображению
                  target="_blank"
                  rel="noreferrer">
                  Просмотр изображения
                </a>
              </td>
              <td className={styles['table-cell']}>
                <a
                  className={styles['a-cell']}
                  onClick={() => openPopup(product.id, product.categoryName)}>
                  {product.categoryName} <img className={styles['img-cell']} src={linksvg} />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {popupOpen && selectedProductId && (
        <div className="product__popup--allwindow" onClick={() => closePopup()}>
          <div className="product__popup" onClick={(e) => e.stopPropagation()}>
            <Popup
              title={selectedPTitle}
              id={selectedProductId}
              onClose={closePopup}
              onAdmin={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListProduct;
