import React, { useState, useEffect } from 'react';
import EditAssortiment from './EditAssortiment';

export default function SelectAndEditAssortiment({ assortiments, setAssortiments }) {
  const [selectedAssortiment, setSelectedAssortiment] = useState(''); // Изначально устанавливаем пустую строку
  const [dont, setDont] = useState(false); //

  const handleAssortimentChange = (e) => {
    setSelectedAssortiment(e.target.value);
  };

  useEffect(() => {
    if (assortiments.length > 0 && !selectedAssortiment) {
      setSelectedAssortiment(assortiments[0].id); // Изменено на ID первого ассортимента
    }
  }, [assortiments, selectedAssortiment]);

  if (!dont) {
    return <h2>Пока недоступно</h2>;
  }
  return (
    <div>
      <h2>Выберите ассортимент для редактирования:</h2>
      <select name="assortimentId" value={selectedAssortiment} onChange={handleAssortimentChange}>
        <option value="">Выберите ассортимент</option>
        {assortiments.map((assortiment) => (
          <option key={assortiment.id} value={assortiment.id}>
            {assortiment.namenklaturoboznach}
          </option>
        ))}
      </select>

      {selectedAssortiment && <EditAssortiment assortiment={selectedAssortiment} />}
    </div>
  );
}
