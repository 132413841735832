import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sortType: { name: 'популярности', sortProps: 'rating' },
  isDesc: true,
};

export const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setIsDesc: (state, action) => {
      state.isDesc = action.payload;
    },
  },
});

export const { setSortType, setIsDesc } = sortSlice.actions;

export default sortSlice.reducer;
