import React, { useState } from 'react';
import axios from 'axios';

export default function UpdatePDF() {
  const [pdfFile, setPdfFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // Состояние для прогресса загрузки

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPdfFile(file);
  };

  const handleUpdatePDF = async () => {
    try {
      if (!pdfFile) {
        setMessage({ text: 'Выберите PDF файл для загрузки', color: 'red' });
        return;
      }

      const formData = new FormData();
      formData.append('pdf', pdfFile);

      const response = await axios.put('https://server.puskateli.by/update-pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        },
      });

      if (response.data.success) {
        setMessage({ text: 'PDF файл успешно изменен', color: 'green' });
      } else {
        setMessage({ text: response.data.message, color: 'red' });
      }
    } catch (error) {
      console.error('Ошибка при изменении PDF файла:', error);
      setMessage({ text: 'Ошибка при изменении PDF файла', color: 'red' });
    }
  };

  const handleViewPDF = async () => {
    try {
      // Просто перенаправляем пользователя на URL для скачивания PDF файла
      window.location.href = 'https://server.puskateli.by/download-pdf';
    } catch (error) {
      console.error('Ошибка при скачивании PDF файла:', error);
      setMessage({ text: 'Ошибка при скачивании PDF файла', color: 'red' });
    }
  };

  return (
    <div>
      <h2>Изменение и просмотр PDF файла:</h2>
      <div>
        <input
          style={{ margin: '10px' }}
          className="button"
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
        />
      </div>
      <div>
        <button className="button" onClick={handleUpdatePDF}>
          Загрузить и сохранить PDF файл
        </button>
        <button className="button" onClick={handleViewPDF}>
          Просмотреть PDF файл
        </button>
      </div>
      {uploadProgress > 0 && (
        <p style={{ color: 'blue' }}>Прогресс загрузки: {uploadProgress.toFixed(2)}%</p>
      )}
      {message && <p style={{ color: message.color }}>{message.text}</p>}
    </div>
  );
}
