import React, { useState, useEffect } from 'react';
import style from './ContactForm.module.scss';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

export default function ContactForm() {
  const selectedTitle = useSelector((state) => state.cart.products);
  const [organisation, setOrganisation] = useState('');
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Функция для отправки данных на сервер
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверка на пустые значения
    if (!organisation || !emailOrPhone) {
      setErrorMessage(
        'Заполните обязательные поля: Организация и информация о номере телефона или email',
      );
      setTimeout(() => {
        setErrorMessage(''); // Очистите сообщение об ошибке через 5 секунд
      }, 5000);
      return; // Остановите выполнение функции
    }

    // Создайте объект с данными для отправки на сервер
    const contactData = {
      organisation,
      emailorphone: emailOrPhone,
      message,
      titleProduct: selectedTitle.length > 0 ? selectedTitle[0].title : '', // Учтем поле "titleProduct"
    };

    try {
      // Отправьте данные на сервер
      const response = await axios.post('https://server.puskateli.by/send-contact', contactData);

      if (response.data.success) {
        // Установите сообщение об успехе
        setSuccessMessage(
          'Заявка оформлена. Ожидайте звонка специалиста. Вас перенаправит на главную страницу автоматически!',
        );

        // Через 5 секунд выполните перенаправление
        setTimeout(() => {
          navigate('/'); // Используйте функцию navigate для перенаправления
          setSuccessMessage(''); // Очистите сообщение об успехе после перенаправления
        }, 5000);
      } else {
        // Обработайте ошибку, если не удалось добавить контакт
        setErrorMessage('Ошибка при добавлении контакта: ' + response.data.message);
        setTimeout(() => {
          setErrorMessage(''); // Очистите сообщение об ошибке через 5 секунд
        }, 5000);
      }
    } catch (error) {
      // Обработайте ошибку сети или другие ошибки
      setErrorMessage('Ошибка при отправке данных на сервер: ' + error.message);
      setTimeout(() => {
        setErrorMessage(''); // Очистите сообщение об ошибке через 5 секунд
      }, 5000);
    }
  };

  return (
    <>
      {successMessage && (
        <div className={`${style.message} ${style.success}`}>{successMessage}</div>
      )}
      {errorMessage && <div className={`${style.message} ${style.error}`}>{errorMessage}</div>}
      <div className={style.container}>
        <form className={style.root} onSubmit={handleSubmit}>
          <p>Введите контактные данные для обратной связи</p>
          <input
            className={style.input}
            type="text"
            placeholder="Организация"
            value={organisation}
            onChange={(e) => setOrganisation(e.target.value)}
          />
          <input
            className={style.input}
            type="text"
            placeholder="Контактный email или телефон"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
          />
          <textarea
            className={style.message}
            type="text"
            placeholder="Сообщение (можно оставить пустым)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="cart__bottom">
            <div className="cart__bottom-buttons">
              <Link to="/" className="button button--outline button--add go-back-btn">
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 13L1 6.93015L6.86175 1"
                    stroke="#D3D3D3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>
                </svg>
                <span>Вернуться назад</span>
              </Link>
              <div className="button pay-btn" onClick={handleSubmit}>
                <span>Заказать звонок</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
