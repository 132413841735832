import React, { useEffect, useState } from 'react';
import styles from './Account.module.scss';
import axios from 'axios';
import UserInfo from './UserInfo';
import Role from './Role';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from '../../../redux/slices/authSlice';
import SecuritySettings from './SecuritySettings';

export default function Account() {
  const [indexCategory, setIndexCategory] = React.useState(1);
  const userId = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get('token');

    if (token) {
      fetch('https://server.puskateli.by/check-token', {
        method: 'GET',
        headers: {
          Auth: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.userId) {
            dispatch(setUserId(data.userId));
          } else {
            Cookies.remove('token');
          }
        })
        .catch((error) => {
          console.error('Ошибка при проверке токена:', error);
        });
    }
  }, [userId]);

  useEffect(() => {
    // Выполняем GET-запрос к backend'у, чтобы получить информацию о пользователе
    axios
      .get(`https://server.puskateli.by/users/${userId}`)
      .then((response) => {
        setUserData(response.data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Ошибка при получении информации о пользователе:', error);
        setLoading(false);
      });
  }, [userId]);

  return (
    <div className={styles.upload_form}>
      <div className={styles.categories}>
        <button className="button button--add" onClick={() => setIndexCategory(1)}>
          Информация о пользователе
        </button>
        <button className="button" onClick={() => setIndexCategory(2)}>
          Права
        </button>
        {/* <button className="button" onClick={() => setIndexCategory(3)}>
          Управление пользователями
        </button> */}
        <button className="button" onClick={() => setIndexCategory(4)}>
          Настройки безопасности
        </button>
      </div>
      <div>{indexCategory === 1 && <UserInfo loading={loading} userData={userData} />}</div>
      <div>{indexCategory === 2 && <Role role={userData.role} />}</div>
      <div>
        {indexCategory === 4 && <SecuritySettings nameUser={userData.name} userId={userId} />}
      </div>

    </div>
  );
}
