import React from 'react';
import styles from './ListAssortiment.module.scss'; // Убедитесь, что путь к файлу стилей верный
import axios from 'axios';

export default function ListAssortiment({ assortiment, loading, setAssortiment, setLoading }) {
  if (loading) {
    return (
      <div className={styles['product-table']}>
        <table>
          <thead>
            <tr className={styles['table-header']}>
              <th>Загрузка...</th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
  if (assortiment.length === 0) {
    return (
      <div className={styles['product-table']}>
        <table>
          <thead>
            <tr className={styles['table-header']}>
              <th>Нет данных в ассортименте</th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }

  return (
    <div>
      <h2>Ассортимент товаров</h2>
      <table className={styles['product-table']}>
        <thead>
          <tr className={styles['table-header']}>
            <th>Номенклатурное обозначение</th>
            <th>Номинальный ток</th>
            <th>Исполнение по износостойкости</th>
            <th>Исполнение по вольтажу цепи управления, ВАС</th>
            <th>Исполнение контактов вспомогательной цепи</th>
            <th>Степень защиты</th>
            <th>Климатическое исполнение и размещение</th>
            <th>Назначение Нереверсивный (Н) / Реверсивный (Р)</th>
            <th>Крепление пускателя</th>
            <th>Размеры, мм (B, L, H)</th>
            <th>Масса, кг</th>
          </tr>
        </thead>
        <tbody>
          {assortiment.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? styles['even-row'] : styles['odd-row']}>
              <td className={styles['table-cell']}>{item.nomenklaturoboznach}</td>
              <td className={styles['table-cell']}>{item.nominalnyytok}</td>
              <td className={styles['table-cell']}>{item.ispolnpoiznos}</td>
              <td className={styles['table-cell']}>{item.voltageControlCircuit}</td>
              <td className={styles['table-cell']}>{item.ispolnkontvspcep}</td>
              <td className={styles['table-cell']}>{item.stepzashity}</td>
              <td className={styles['table-cell']}>{item.klimatispoln}</td>
              <td className={styles['table-cell']}>{item.naznachnerorrev}</td>
              <td className={styles['table-cell']}>{item.krepleniyepuskatelya}</td>
              <td
                className={
                  styles['table-cell']
                }>{`${item.sizeb} x ${item.sizel} x ${item.sizeh}`}</td>
              <td className={styles['table-cell']}>{item.massakg}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
