import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './ToDoList.module.scss'; // Импортируем стили

export default function TaskManager() {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [showCompletedTasks, setShowCompletedTasks] = useState(false); // Добавляем состояние для показа завершенных задач
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false); // Состояние модального окна подтверждения

  useEffect(() => {
    // Загрузка актуальных или завершенных задач в зависимости от состояния
    const endpoint = showCompletedTasks ? 'completed-tasks' : 'tasks';
    axios
      .get(`https://server.puskateli.by/${endpoint}`)
      .then((response) => {
        setTasks(response.data);
      })
      .catch((error) => {
        console.error(
          `Ошибка при загрузке ${showCompletedTasks ? 'завершенных' : 'актуальных'} задач:`,
          error,
        );
      });
  }, [showCompletedTasks]);

  const createTask = () => {
    if (selectedTask && newTaskDescription) {
      // Создание задачи (обновление существующей записи)
      axios
        .put(`https://server.puskateli.by/tasks/${selectedTask.id}`, {
          taskDescription: newTaskDescription,
        })
        .then((response) => {
          if (response.data.success) {
            // Обновление списка задач
            setTasks([...tasks, response.data.task]);
            setNewTaskDescription('');
            setSelectedTask(null);
          } else {
            console.error('Ошибка при создании задачи:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Ошибка при создании задачи:', error);
        });
    }
  };

  const toggleTaskCompletion = (taskId, isCompleted) => {
    if (isCompleted) {
      // Открываем модальное окно подтверждения
      setConfirmationModalOpen(true);
      return;
    }
    // Удаление задачи (отметка как завершенной)
    axios
      .put(`https://server.puskateli.by/tasks/${taskId}/complete`)
      .then((response) => {
        if (response.data.success) {
          // Обновление списка задач
          const updatedTasks = tasks.map((task) => {
            if (task.id === taskId) {
              task.isCompleted = true;
            }
            return task;
          });
          setTasks(updatedTasks);
        } else {
          console.error('Ошибка при завершении задачи:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Ошибка при завершении задачи:', error);
      });
  };

  // Функция для закрытия модального окна и возврата задачи в актуальные
  const cancelCompletion = () => {
    setConfirmationModalOpen(false);
    // Вернуть задачу в актуальные задачи
    const updatedTasks = tasks.map((task) => {
      if (task.id === selectedTask.id) {
        task.isCompleted = false;
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  // Отображение описания задачи
  const taskDescription = selectedTask ? selectedTask.taskDescription : '';

  return (
    <div className={styles['task-manager']}>
      <h2>Задачи</h2>
      <div className={styles['task-manager-controls']}>
        <select
          value={selectedTask ? selectedTask.id : ''}
          onChange={(e) => {
            const selectedId = e.target.value;
            const selectedTask = tasks.find((task) => task.id === parseInt(selectedId));
            setSelectedTask(selectedTask);
          }}>
          <option value="">Выберите задачу</option>
          {tasks.map((task) => (
            <option key={task.id} value={task.id}>
              {task.organisation}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Описание задачи"
          value={newTaskDescription}
          onChange={(e) => setNewTaskDescription(e.target.value)}
        />
        <button onClick={createTask} className={styles['task-manager-button']}>
          Добавить задачу
        </button>
        <button onClick={() => setShowCompletedTasks(!showCompletedTasks)}>
          {showCompletedTasks ? 'Показать актуальные задачи' : 'Показать завершенные задачи'}
        </button>
      </div>
      <h3>Описание задачи</h3>
      <p>{taskDescription}</p>
      <h3>Задачи</h3>
      <ul className={styles['task-list']}>
        {tasks.map((task) => (
          <li key={task.id} className={styles['task-list-item']}>
            {!task.isCompleted ? (
              <input
                type="checkbox"
                checked={task.isCompleted}
                onChange={() => toggleTaskCompletion(task.id, task.isCompleted)}
              />
            ) : (
              <span className={styles['completed-task']}>Завершена</span>
            )}
            {task.organisation}
          </li>
        ))}
      </ul>
      {/* Модальное окно подтверждения */}
      {confirmationModalOpen && (
        <div className={styles['confirmation-modal']}>
          <p>Вы уверены, что хотите закрыть эту задачу?</p>
          <button onClick={cancelCompletion}>Отмена</button>
          <button onClick={() => toggleTaskCompletion(selectedTask.id, selectedTask.isCompleted)}>
            Подтвердить
          </button>
        </div>
      )}
    </div>
  );
}
