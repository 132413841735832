import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Message = ({ message, messageType }) => {
  const messageStyles = {
    success: { color: 'green' },
    error: { color: 'red' },
    warning: { color: 'orange' },
  };

  return (
    <div style={{ ...messageStyles[messageType], display: message ? 'block' : 'none' }}>
      {message}
    </div>
  );
};

const UpdateCategory = ({ categories, onUpdateCategory }) => {
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [choose, setChoose] = useState(false);

  const [message, setMessage] = useState(''); // Сообщение для вывода
  const [messageType, setMessageType] = useState(''); // Тип сообщения (success, error, warning)

  useEffect(() => {
    // Очищаем сообщение через 5 секунд
    const timeout = setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 5000);

    return () => clearTimeout(timeout);
  }, [message]);

  const handleCategoryChange = (event) => {
    if (event.target.value === 'Все') {
      setMessage("Нельзя менять категорию 'Все'");
      setMessageType('warning');
      return;
    }
    const selectedCategoryName = event.target.value;
    setSelectedCategoryName(selectedCategoryName);
    setChoose(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Ищем категорию с выбранным именем
    const selectedCategory = categories.find((category) => category.name === selectedCategoryName);

    if (!selectedCategory) {
      setMessage('Выбранная категория не найдена');
      setMessageType('error');
      return;
    }

    try {
      const response = await axios.put(
        `https://server.puskateli.by/categories/${selectedCategory.id}`,
        {
          name: newCategoryName,
        },
      );
      setMessage('Категория успешно обновлена');
      setMessageType('success');
      setChoose(false);
      setSelectedCategoryName('');
      setNewCategoryName('');
    } catch (error) {
      // Обработка ошибок
      setMessage('Ошибка при обновлении категории');
      setMessageType('error');
      console.error('Ошибка при обновлении категории:', error);
    }
  };

  return (
    <>
      <h2>Изменить категорию</h2>
      <Message message={message} messageType={messageType} />
      <form onSubmit={handleSubmit}>
        <div>
          <label>Выберите категорию:</label>
          <select value={selectedCategoryName} onChange={handleCategoryChange}>
            <option value="">Выберите категорию</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        {choose && (
          <>
            <div>
              <label>Новое название категории:</label>
              <input
                type="text"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </div>
            <div>
              <button type="submit">Сохранить</button>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default UpdateCategory;
