import React from 'react';
import styles from './ManageProduct.module.scss';
import axios from 'axios';
import AddProduct from './AddProduct';
import ListProduct from './ListProduct';
import EditProduct from './EditProduct';
import DeleteProduct from './DeleteProduct';

export default function ManageProduct() {
  const [products, setProducts] = React.useState([]);
  const [indexCategory, setIndexCategory] = React.useState(1);

  React.useEffect(() => {
    // Отправить GET-запрос на сервер для получения списка категорий
    axios
      .get('https://server.puskateli.by/items-admin')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories', error);
      });
  }, [indexCategory]);

  return (
    <div className={styles.upload_form}>
      <div className={styles.categories}>
        <button className="button button--add" onClick={() => setIndexCategory(1)}>
          Список товаров
        </button>
        <button className="button" onClick={() => setIndexCategory(2)}>
          Добавить товар
        </button>
        <button className="button" onClick={() => setIndexCategory(3)}>
          Изменить товар
        </button>
        <button className="button" onClick={() => setIndexCategory(4)}>
          Удалить товар
        </button>
      </div>
      <div>{indexCategory === 1 && <ListProduct products={products} />}</div>
      <div>{indexCategory === 2 && <AddProduct />}</div>
      <div>
        {indexCategory === 3 && <EditProduct products={products} setProducts={setProducts} />}
      </div>
      <div>{indexCategory === 4 && <DeleteProduct />}</div>
    </div>
  );
}
