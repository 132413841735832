import React, { useEffect, useState } from 'react';
import styles from './ClosedClients.module.scss';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setUserId, setRole } from '../../../../redux/slices/authSlice';
import ReactModal from 'react-modal';

export default function ClosedClients({ refresh, setRefresh }) {
  const [clientitem, setClientitem] = useState([]); // Состояние для хранения данных о клиентах
  const [usernames, setUsernames] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [thisUser, setThisUser] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isCloseConfirmationModalOpen, setIsCloseConfirmationModalOpen] = useState(false);
  const [refreshLocal, setRefreshLocal] = React.useState(true);

  ReactModal.setAppElement('#root');

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get('https://server.puskateli.by/list-contacts-disable');
        if (response.data) {
          setClientitem(response.data.contacts);
        }
      } catch (error) {
        console.error('Ошибка при получении данных о клиентах:', error);
      }
    };
    fetchClientData();
  }, [refreshLocal]);

  // Эффект для загрузки имен пользователей на основе данных клиентов
  useEffect(() => {
    const fetchUsernames = async () => {
      const usernamesData = {};
      for (const item of clientitem) {
        if (item.idUser) {
          try {
            const response = await axios.get(`https://server.puskateli.by/users/${item.idUser}`);
            if (response.data.success) {
              usernamesData[item.id] = response.data.result.name;
            }
          } catch (error) {
            console.error('Ошибка при получении имени пользователя:', error);
          }
        }
      }
      setUsernames(usernamesData);
    };
    fetchUsernames();
  }, [clientitem]);

  const userId = useSelector((state) => state.auth.userId); // Получаем активный userId из Redux
  const role = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      fetch('https://server.puskateli.by/check-token', {
        method: 'GET',
        headers: {
          Auth: token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.userId) {
            dispatch(setUserId(data.userId));
          } else {
            Cookies.remove('token');
          }
        })
        .catch((error) => {
          console.error('Ошибка при проверке токена:', error);
        });
    }
  }, []);

  useEffect(() => {
    axios
      .get(`https://server.puskateli.by/users/${userId}`)
      .then((response) => {
        setThisUser(response.data.result);
        dispatch(setRole(response.data.result.role));
      })
      .catch((error) => {
        console.error('Ошибка при получении информации о пользователе:', error);
      });
  }, [userId]);

  useEffect(() => {
    // Загрузим список доступных менеджеров, исключая текущего пользователя (если есть)
    axios
      .get(`https://server.puskateli.by/users`)
      .then((response) => {
        const managers = response.data.users.filter(
          (user) => user.role === 'User' && user.id !== userId,
        );
        setAvailableManagers(managers);
      })
      .catch((error) => {
        console.error('Ошибка при получении списка менеджеров:', error);
      });
  }, [userId]);

  const openModal = (id) => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItemId(null);
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
  };

  const openCloseConfirmationModal = (id) => {
    setSelectedItemId(id);
    setIsCloseConfirmationModalOpen(true);
  };

  const handleManagerClick = (clientId) => {
    // Проверяем, что роль пользователя "Admin", прежде чем открыть модальное окно
    if (role === 'Admin') {
      setSelectedItemId(clientId);
      setIsConfirmModalOpen(true);
    } else {
      alert('Вы не имеете прав для смены менеджера.');
    }
  };

  const handleUserSelection = (userId, name) => {
    setSelectedUserId(userId);
    setSelectedUsername(name);
  };

  const handleUserConfirmation = async (confirmed) => {
    if (confirmed && selectedItemId && (selectedUserId || userId)) {
      try {
        const response = await axios.put(
          `https://server.puskateli.by/update-idUser/${selectedItemId}`,
          {
            idUser: selectedUserId || userId,
          },
        );
        if (response.data.success) {
          setRefresh(!refresh);
          setRefreshLocal(!refreshLocal);
        } else {
          console.error('Ошибка при закреплении заявки:', response.data.message);
        }
      } catch (error) {
        console.error('Ошибка при отправке данных на сервер:', error);
      }
    }
    closeModal();
  };

  function isEmailValid(email) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0.9.-]+\.[A-Za-z]{2,}$/;
    return emailPattern.test(email);
  }

  const mailto = 'mailto:';
  const tel = 'tel:';

  // Эффект для загрузки примечаний на основе данных клиентов
  useEffect(() => {
    const fetchDescriptions = async () => {
      const descriptionsData = {};
      for (const item of clientitem) {
        try {
          const response = await axios.get(
            `https://server.puskateli.by/contacts/${item.id}/description`,
          );
          if (response.data.success) {
            descriptionsData[item.id] = response.data.description;
          }
        } catch (error) {
          console.error('Ошибка при получении примечания:', error);
        }
      }
      setDescriptions(descriptionsData);
    };
    fetchDescriptions();
  }, [clientitem]);

  const openDescriptionModal = async (contactId, visible) => {
    setSelectedItemId(contactId);
    visible && setIsDescriptionModalOpen(true);
  };

  const saveDescription = async () => {
    try {
      await axios.put(`https://server.puskateli.by/contacts/${selectedItemId}/description`, {
        description: descriptions[selectedItemId],
      });
      setIsDescriptionModalOpen(false);
    } catch (error) {
      console.error('Ошибка при сохранении примечания:', error);
    }
  };

  const handleCloseConfirmation = async () => {
    try {
      const response = await axios.put(
        `https://server.puskateli.by/open-contact/${selectedItemId}`,
      );
      if (response.data.success) {
        // Закрытие контакта прошло успешно, обновите данные компонента
        setRefresh(!refresh);
        setRefreshLocal(!refreshLocal);
      } else {
        console.error('Ошибка при закрытии контакта:', response.data.message);
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса на закрытие контакта:', error);
    }
    setIsCloseConfirmationModalOpen(false);
  };
  const filterClients = () => {
    return clientitem.filter((itemclient) => {
      // Фильтрация по полю "Организация"
      return itemclient.organisation.toLowerCase().includes(searchQuery.toLowerCase());
    });
  };
  return (
    <div>
      <h2>Активные клиенты</h2>
      <input
        type="text"
        placeholder="Поиск по организации..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <table className={styles['product-table']}>
        <thead>
          <tr>
            <th className={styles['table-header']}>Организация</th>
            <th className={styles['table-header']}>Контакты</th>
            <th className={styles['table-header']}>Сообщение (если есть)</th>
            <th className={styles['table-header']}>Менеджер</th>
            <th className={styles['table-header']}>Примечания</th>
            <th className={styles['table-header']}>Обработка</th>
          </tr>
        </thead>
        <tbody>
          {filterClients().map((itemclient) => (
            <tr key={itemclient.id}>
              <td className={styles['table-cell']}>{itemclient.organisation} </td>
              <td className={styles['table-cell']}>
                {isEmailValid(itemclient.emailorphone) ? (
                  <a href={mailto + itemclient.emailorphone}>{itemclient.emailorphone}</a>
                ) : (
                  <a href={tel + itemclient.emailorphone}>{itemclient.emailorphone}</a>
                )}
              </td>
              <td className={styles['table-cell']}>
                {itemclient.message ? itemclient.message : '-'}{' '}
                {itemclient.titleProduct ? <b>({itemclient.titleProduct})</b> : ''}
              </td>
              <td className={styles['table-cell']}>
                {usernames[itemclient.id] ? (
                  <span
                    onClick={() => handleManagerClick(itemclient.id)}
                    className={styles['span-cell']}>
                    {usernames[itemclient.id]}
                  </span>
                ) : (
                  <p className={styles['a-cell']} onClick={() => openModal(itemclient.id)}>
                    Закрепиться
                  </p>
                )}
              </td>
              <td className={styles['table-cell']}>
                {descriptions[itemclient.id] === '' || descriptions[itemclient.id] === null ? (
                  <span
                    className={styles['a-cell']}
                    onClick={() => openDescriptionModal(itemclient.id, true)}>
                    Добавить примечание
                  </span>
                ) : (
                  <p
                    className={styles['a-cell']}
                    onClick={() => openDescriptionModal(itemclient.id, true)}>
                    {descriptions[itemclient.id]}
                  </p>
                )}
              </td>
              <td className={styles['table-cell']}>
                <button
                  className="button"
                  onClick={() => openCloseConfirmationModal(itemclient.id)}>
                  Вернуть
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Закрепить заявку"
        className={styles['custom-modal']}
        overlayClassName={styles['custom-overlay']}>
        <h2>Вы действительно хотите закрепить за собой данную заявку?</h2>
        <button className={styles['modal-button']} onClick={() => handleUserConfirmation(true)}>
          Да
        </button>
        <button className={styles['modal-button']} onClick={() => handleUserConfirmation(false)}>
          Нет
        </button>
      </ReactModal>
      {role === 'Admin' && (
        <ReactModal
          isOpen={isConfirmModalOpen}
          onRequestClose={() => setIsConfirmModalOpen(false)}
          contentLabel="Подтверждение"
          className={styles['custom-modal']}
          overlayClassName={styles['custom-overlay']}>
          <h2>Выберите пользователя, за которым хотите закрепить заявку:</h2>
          <ul>
            {availableManagers.map((manager) => (
              <li key={manager.id}>
                <button
                  className={styles['modal-button']}
                  onClick={() => handleUserSelection(manager.id, manager.name)}>
                  {manager.name}
                </button>
              </li>
            ))}
          </ul>
          {selectedUserId && <p>Вы выбрали: {selectedUsername}</p>}
          <button className={styles['modal-button']} onClick={() => handleUserConfirmation(true)}>
            Да
          </button>
          <button className={styles['modal-button']} onClick={() => handleUserConfirmation(false)}>
            Нет
          </button>
        </ReactModal>
      )}
      {/* Модальное окно для примечания */}
      <ReactModal
        isOpen={isDescriptionModalOpen}
        onRequestClose={() => setIsDescriptionModalOpen(false)}
        contentLabel="Примечание"
        className={styles['custom-modal']}
        overlayClassName={styles['custom-overlay']}>
        <h2>Примечание:</h2>
        <textarea
          rows="4"
          cols="50"
          value={descriptions[selectedItemId] || ''}
          onChange={(e) =>
            setDescriptions((prevDescriptions) => ({
              ...prevDescriptions,
              [selectedItemId]: e.target.value,
            }))
          }
          className={`${styles['textarea-description']}`}
        />
        <button className={styles['modal-button']} onClick={saveDescription}>
          Сохранить
        </button>
      </ReactModal>

      <ReactModal
        isOpen={isCloseConfirmationModalOpen}
        onRequestClose={() => setIsCloseConfirmationModalOpen(false)}
        contentLabel="Подтверждение закрытия"
        className={styles['custom-modal']}
        overlayClassName={styles['custom-overlay']}>
        <h2>Вы действительно хотите вернуть эту заявку?</h2>
        <button className={styles['modal-button']} onClick={handleCloseConfirmation}>
          Да
        </button>
        <button
          className={styles['modal-button']}
          onClick={() => setIsCloseConfirmationModalOpen(false)}>
          Нет
        </button>
      </ReactModal>
    </div>
  );
}
