import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addItem } from '../redux/slices/cartSlice';
import axios from 'axios';

export default function Popup({ title, id, onClose, onAdmin }) {
  const dispatch = useDispatch();

  const [data, setData] = React.useState([]);
  const [rele, setRele] = React.useState(true);

  React.useEffect(() => {
    // Вызовите роут на бэкенде для получения данных по productid
    axios
      .get('https://server.puskateli.by/getProductData', { params: { productid: id } })
      .then((response) => {
        setData(response.data);
        if (response.data[0].withRele) {
          setRele(response.data[0].withRele);
        }
      })
      .catch((error) => {
        console.error('Error fetching product data', error);
      });
  }, [id]);

  const onClickCalling = () => {
    const item = {
      title,
    };
    onClose(false);
    dispatch(addItem(item));
  };

  return (
    <>
      <h1>Ассортимент {title}</h1>
      <p className="hint-assortiment"> </p>
      <div className="popup__items">
        <table>
          <thead>
            <tr>
              <th>Номенклатурное обозначение</th>
              <th>Номинальный ток</th>
              <th>Исполнение по износостойкости</th>
              <th>Исполнение по вольтажу цепи управления, ВАС</th>
              <th>Исполнение контактов вспомогательной цепи</th>
              <th>Степень защиты</th>
              <th>Климатическое исполнение и размещение</th>
              <th>Назначение Нереверсивный (Н) / Реверсивный (Р)</th>
              <th>Крепление пускателя</th>
              <th>Размеры, мм (B, L, H)</th>
              <th>Масса, кг</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item['productid']}>
                <td className="bolded">{item['nomenklaturoboznach']}</td>
                <td>{item['nominalnyytok']}</td>
                <td>{item['ispolnpoiznos']}</td>
                <td>{item['ispolnkontvspcep']}</td>
                <td>{item['voltageControlCircuit']}</td>
                <td>{item['stepzashity']}</td>
                <td>{item['klimatispoln']}</td>
                <td>{item['naznachnerorrev']}</td>
                <td>{item['krepleniyepuskatelya']}</td>
                <td>{`${item['sizeb']} x ${item['sizel']} x ${item['sizeh']}`}</td>
                <td>{item['massakg']}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {rele && <p></p>}
      {!onAdmin && (
        <Link to="/contact" onClick={onClickCalling}>
          <button className="add-button">Заказать звонок</button>
        </Link>
      )}
      <button className="cancel-button" onClick={() => onClose(false)}>
        Отменить
      </button>
    </>
  );
}
