import React from 'react';
import styles from './Clients.module.scss';
import axios from 'axios';
import ListClients from './ListClients';
import ClosedClients from './ClosedClients';
import ToDoList from './ToDoList';

export default function Clients() {
  const [indexCategory, setIndexCategory] = React.useState(1);
  const [clientsitem, setClientsitem] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);

  React.useEffect(() => {
    // Отправить GET-запрос на сервер для получения списка категорий
    axios
      .get('https://server.puskateli.by/list-contacts-active')
      .then((response) => {
        setClientsitem(response.data.contacts);
      })
      .catch((error) => {
        console.error('Error fetching categories', error);
      });
  }, [indexCategory, refresh]);

  return (
    <div className={styles.upload_form}>
      <div className={styles.categories}>
        <button className="button button--add" onClick={() => setIndexCategory(1)}>
          Заявки
        </button>
        <button className="button" onClick={() => setIndexCategory(2)}>
          Обработанные
        </button>
        {/* <button className="button" onClick={() => setIndexCategory(3)}>
          Планировщик
        </button> */}
      </div>
      <div>
        {indexCategory === 1 && (
          <ListClients clientitem={clientsitem} refresh={refresh} setRefresh={setRefresh} />
        )}
        {indexCategory === 2 && <ClosedClients refresh={refresh} setRefresh={setRefresh} />}
        {indexCategory === 3 && <ToDoList />}
      </div>
    </div>
  );
}
