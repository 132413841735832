import React, { useState } from 'react';
import ManageCategories from '../Components/AdminPanel/ManageCategories';
import ManageProduct from '../Components/AdminPanel/ManageProduct';
import Account from '../Components/AdminPanel/Account';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ManageAssortiment from '../Components/AdminPanel/ManageAssortiment';
import SettingsApp from '../Components/AdminPanel/SettingsApp';
import Clients from '../Components/AdminPanel/Clients';

const Admin = () => {
  const [selectedMenu, setSelectedMenu] = useState('addProduct');
  const navigate = useNavigate();
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const exitApp = () => {
    Cookies.remove('token');
    navigate('/login');
  };

  return (
    <div className="admin-container">
      <div className="admin-menu">
        <button
          className={`button button--adminpanel ${selectedMenu === 'addProduct' ? 'active' : ''}`}
          onClick={() => handleMenuClick('addProduct')}>
          Управление товарами
        </button>
        <button
          className={`button button--adminpanel ${
            selectedMenu === 'manageCategories' ? 'active' : ''
          }`}
          onClick={() => handleMenuClick('manageCategories')}>
          Управление категориями
        </button>
        <button
          className={`button button--adminpanel ${selectedMenu === 'assortiment' ? 'active' : ''}`}
          onClick={() => handleMenuClick('assortiment')}>
          Ассортимент товаров
        </button>
        <button
          className={`button button--adminpanel ${selectedMenu === 'Clients' ? 'active' : ''}`}
          onClick={() => handleMenuClick('Clients')}>
          База клиентов
        </button>
        <button
          className={`button button--adminpanel ${selectedMenu === 'account' ? 'active' : ''}`}
          onClick={() => handleMenuClick('account')}>
          Аккаунт
        </button>
        <button
          className={`button button--adminpanel ${selectedMenu === 'settings' ? 'active' : ''}`}
          onClick={() => handleMenuClick('settings')}>
          Настройки сайта
        </button>
        <Link to="/">
          <button
            className={`button button--adminpanel  ${selectedMenu === 'main' ? 'active' : ''}`}
            onClick={() => handleMenuClick('main')}>
            Вернуться на главную страницу сайта
          </button>
        </Link>
        <button
          className={`button button--adminpanel ${selectedMenu === 'exit' ? 'active' : ''}`}
          onClick={() => handleMenuClick('exit')}>
          Выход
        </button>
      </div>
      <div className="admin-content">
        {selectedMenu === 'addProduct' && <ManageProduct />}
        {selectedMenu === 'manageCategories' && <ManageCategories />}
        {selectedMenu === 'account' && <Account />}
        {selectedMenu === 'assortiment' && <ManageAssortiment />}
        {selectedMenu === 'settings' && <SettingsApp />}
        {selectedMenu === 'Clients' && <Clients />}
        {selectedMenu === 'exit' && exitApp()}
      </div>
    </div>
  );
};

export default Admin;
