import React from 'react';
import styles from './ManageCategories.module.scss';

export default function ListCategories({ categories }) {
  return (
    <div className={styles.categories}>
      <h2>Список действующих категорий</h2>
      <ul>
        {categories.map((category) => (
          <li key={category.id}>{category.name}</li>
        ))}
      </ul>
    </div>
  );
}
