import React from 'react'
import Autorisation from '../Components/LoginBlock'

export default function Auth() {
  return (
    <>
        <Autorisation/>
    </>
  )
}
