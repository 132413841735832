import React from 'react';

const Role = ({ role }) => {
  let permissions = [];

  switch (role) {
    case 'Admin':
      permissions = ['Ограничений нет'];
      break;
    case 'User':
      permissions = [
        'Просмотр данных',
        'CRUD операции',
        'Изменение собственных данных',
        'Просмотр базы заказчиков',
      ];
      break;
    default:
      permissions = ['Нет доступных прав'];
      break;
  }

  return (
    <div>
      <h3>Права пользователя:</h3>
      <ul>
        {permissions.map((permission, index) => (
          <li key={index}>{permission}</li>
        ))}
      </ul>
    </div>
  );
};

export default Role;
