import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function EditAssortiment({ assortiment }) {
  // Состояния для редактирования данных
  const [formData, setFormData] = useState({
    namenklaturoboznach: assortiment.namenklaturoboznach,
    ispolnpoiznos: assortiment.ispolnpoiznos,
    ispolnkontvspcep: assortiment.ispolnkontvspcep,
    stepzashity: assortiment.stepzashity,
    klimatispoln: assortiment.klimatispoln,
    naznachnerorrev: assortiment.naznachnerorrev,
    acdc: assortiment.acdc,
    sizeb: assortiment.sizeb,
    sizel: assortiment.sizel,
    sizeh: assortiment.sizeh,
    massakg: assortiment.massakg,
    withRele: assortiment.withRele,
  });

  // Функция для обработки изменений в форме
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Обновляем соответствующее значение в состоянии formData
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Функция для отправки обновленных данных на сервер
  const handleSaveChanges = async () => {
    try {
      await axios.put(`https://server.puskateli.by/assortiments/${assortiment.id}`, formData);
      alert('Данные успешно обновлены');
    } catch (error) {
      console.error('Error updating assortiment:', error);
      alert('Ошибка при обновлении данных');
    }
  };

  return (
    <div>
      <h2>Редактирование ассортимента:</h2>
      <form>
        <div>
          <label>Название:</label>
          <input
            type="text"
            name="namenklaturoboznach"
            value={formData.namenklaturoboznach}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Исполнение по износу:</label>
          <input
            type="text"
            name="ispolnpoiznos"
            value={formData.ispolnpoiznos}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Исполнение контактных вставок:</label>
          <input
            type="text"
            name="ispolnkontvspcep"
            value={formData.ispolnkontvspcep}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Степень защиты:</label>
          <input
            type="text"
            name="stepzashity"
            value={formData.stepzashity}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Климат исполнения:</label>
          <input
            type="text"
            name="klimatispoln"
            value={formData.klimatispoln}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Назначение/неразъемный разъем:</label>
          <input
            type="text"
            name="naznachnerorrev"
            value={formData.naznachnerorrev}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>AC/DC:</label>
          <input type="text" name="acdc" value={formData.acdc} onChange={handleInputChange} />
        </div>
        <div>
          <label>Размер B:</label>
          <input type="text" name="sizeb" value={formData.sizeb} onChange={handleInputChange} />
        </div>
        <div>
          <label>Размер L:</label>
          <input type="text" name="sizel" value={formData.sizel} onChange={handleInputChange} />
        </div>
        <div>
          <label>Размер H:</label>
          <input type="text" name="sizeh" value={formData.sizeh} onChange={handleInputChange} />
        </div>
        <div>
          <label>Масса, кг:</label>
          <input type="text" name="massakg" value={formData.massakg} onChange={handleInputChange} />
        </div>
        <div>
          <label>С реле (да - галочка установлена):</label>
          <input
            type="checkbox"
            name="withRele"
            checked={formData.withRele}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <button type="button" onClick={handleSaveChanges}>
            Сохранить изменения
          </button>
        </div>
      </form>
    </div>
  );
}
