import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__info">
          <p>Республика Беларусь, 246050, г. Гомель, ул. Советская, д. 157</p>
          <p>ОАО «Электроаппаратура» <Link to="/login">{'УНП'}</Link> 400051479</p>
        </div>
      </div>
    </footer>
  );
}
