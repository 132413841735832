import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function UpdatePhone() {
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    // Загрузка текущего телефона с сервера при монтировании компонента
    axios
      .get('https://server.puskateli.by/get-phone')
      .then((response) => {
        if (response.data.success) {
          setPhone(response.data.phone);
        } else {
          console.error('Ошибка при загрузке телефона:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке телефона:', error);
      });
  }, []); // Пустой массив зависимостей означает, что useEffect выполняется только при монтировании компонента

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put('https://server.puskateli.by/update-phone', { phone });
      if (response.data.success) {
        setMessage({ text: 'Телефон успешно изменен', color: 'green' });
      } else {
        setMessage({ text: response.data.message, color: 'red' });
      }
    } catch (error) {
      console.error('Ошибка при изменении телефона:', error);
      setMessage({ text: 'Ошибка при изменении телефона', color: 'red' });
    }
  };

  return (
    <div>
      <h2>Изменение телефона:</h2>
      <div>
        <label>Новый телефон:</label>
        <input type="text" value={phone} onChange={handleChange} />
      </div>
      <div>
        <button className="button" onClick={handleUpdate}>
          Сохранить
        </button>
      </div>
      {message && <p style={{ color: message.color }}>{message.text}</p>}
    </div>
  );
}
