import React from 'react';
import styles from './UserInfo.module.scss';

const UserInfo = ({ loading, userData }) => {
  if (loading) {
    return <div className={styles['user-info']}>Загрузка...</div>;
  }

  if (!userData) {
    return <div className={styles['user-info']}>Пользователь не найден</div>;
  }

  return (
    <div>
      <h2 className={styles['user-info__title']}>Информация о пользователе</h2>
      <p className={styles['user-info__label']}>Логин для входа:</p>
      <p className={styles['user-info__value']}>{userData.login}</p>
      <p className={styles['user-info__label']}>Имя пользователя:</p>
      <p className={styles['user-info__value']}>{userData.name}</p>
      {/* <p className={styles['user-info__label']}>Role:</p>
      <p className={styles['user-info__value']}>{userData.role}</p> */}
      <p className={styles['user-info__label']}>Статус:</p>
      <p className={styles['user-info__value']}>{userData.status || 'Пусто'}</p>
      <p className={styles['user-info__label']}>Дата регистрации:</p>
      <p className={styles['user-info__value']}>
        {new Date(userData.dateregistration).toLocaleDateString()}
      </p>
      {/* <p className={styles['user-info__label']}>Дата смены пароля:</p>
      <p className={styles['user-info__value']}>
        {userData.datechangepassword || 'Пароль не менялся'}
      </p> */}
    </div>
  );
};

export default UserInfo;
