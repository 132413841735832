import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './scss/app.scss';

import Header from './Components/Header';
import Footer from './Components/Footer';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Auth from './pages/Auth';
import Cart from './pages/Cart';
import Admin from './pages/Admin';
import Register from './Components/AdminPanel/Account/Register';
import Cookies from 'js-cookie';

function App() {
  return (
    <div className="wrapper">
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Cart />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/registration" element={<Register />} />
          <Route
            path="/admin"
            element={Cookies.get('token') ? <Admin /> : <Navigate to="/login" />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
