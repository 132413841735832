import React, { useState } from 'react';
import axios from 'axios';
import styles from './Register.module.scss';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    login: '',
    password: '',
    name: '',
  });

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [codeRegister, setCodeRegister] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegistration = async () => {
    if (codeRegister === '1121') {
      try {
        const response = await axios.post('https://server.puskateli.by/register', formData);
        setMessage(response.data.message);
        setMessageType('success');

        try {
          const loginResponse = await axios.post('https://server.puskateli.by/login', {
            login: formData.login,
            password: formData.password,
          });

          const token = loginResponse.data.token;

          Cookies.set('token', token, { expires: 30 });

          navigate('/admin');
        } catch (loginError) {
          console.error('Ошибка входа после регистрации:', loginError);
          setMessage('Успешно зарегистрировано, но не удалось войти.');
          setMessageType('error');
        }
      } catch (error) {
        console.error('Ошибка при регистрации:', error);
        setMessage('Не удалось зарегистрироваться');
        setMessageType('error');
      }
    } else {
      alert('Неверный код регистрации');
    }
  };

  return (
    <div className={styles.registrationForm}>
      <h2>Регистрация</h2>
      <form>
        <div className={styles.formGroup}>
          <label htmlFor="login">Логин</label>
          <input
            type="text"
            id="login"
            name="login"
            value={formData.login}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Пароль</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="name">Имя</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="code">Код регистрации</label>
          <input
            type="text"
            id="code"
            name="code"
            value={codeRegister}
            onChange={(e) => setCodeRegister(e.target.value)}
            required
          />
        </div>
        <button type="button" onClick={handleRegistration} className={styles.submitButton}>
          Зарегистрироваться
        </button>
      </form>
      {message && <div className={messageType}>{message}</div>}
    </div>
  );
};

export default Register;
