import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UpdateProduct = () => {
  const [selectedProductId, setSelectedProductId] = useState('');
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    categoryID: '',
    isActive: true,
    image: null,
    pdf: null,
  });
  const [isSelectedProd, setIsSelectedProd] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    // Загрузка списка товаров и категорий при загрузке компонента
    axios
      .get('https://server.puskateli.by/items-admin')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });

    axios
      .get('https://server.puskateli.by/categories')
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleProductChange = (e) => {
    const productId = e.target.value;
    setSelectedProductId(productId);
    setIsSelectedProd(true);

    // Здесь нужно загрузить данные выбранного товара и заполнить поля
    axios
      .get(`https://server.puskateli.by/products/${productId}`)
      .then((response) => {
        const selectedProduct = response.data;

        // Заполняем поля данными выбранного товара
        setFormData({
          title: selectedProduct.title,
          categoryID: selectedProduct.categoryID,
          isActive: selectedProduct.isActive,
          image: null, // Если не хотите менять изображение, оставьте как null
          pdf: null, // Если не хотите менять PDF, оставьте как null
        });
      })
      .catch((error) => {
        console.error('Error fetching product details:', error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file,
    });
  };

  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      pdf: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверяем, что все необходимые поля заполнены
    if (!formData.title || !formData.categoryID || formData.isActive === undefined) {
      setMessage('Пожалуйста, заполните все обязательные поля.');
      setMessageType('error');
      return;
    }

    // Создайте объект FormData для отправки файлов
    const formDataForFiles = new FormData();
    formDataForFiles.append('image', formData.image);
    formDataForFiles.append('pdf', formData.pdf);

    // Добавьте остальные данные к форме
    formDataForFiles.append('title', formData.title);
    formDataForFiles.append('categoryID', formData.categoryID);
    formDataForFiles.append('isActive', formData.isActive);

    try {
      await axios.put(
        `https://server.puskateli.by/products/${selectedProductId}`,
        formDataForFiles,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Устанавливаем заголовок для формы с файлами
          },
        },
      );
      setMessage('Изменения сохранены успешно');
      setMessageType('success');
    } catch (error) {
      console.error('Ошибка при сохранении изменений товара:', error);
      setMessage('Не удалось сохранить изменения товара');
      setMessageType('error');
    }

    setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 5000);
  };

  return (
    <div>
      <h2>Изменение данных о товаре</h2>
      <select onChange={handleProductChange} value={selectedProductId}>
        <option value="">Выберите товар</option>
        {products.map((product) => (
          <option key={product.id} value={product.id}>
            {product.title}
          </option>
        ))}
      </select>
      {isSelectedProd && (
        <form onSubmit={handleSubmit}>
          <div>
            <label>Название:</label>
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} />
          </div>
          <div>
            <label>Категория:</label>
            <select name="categoryID" value={formData.categoryID} onChange={handleInputChange}>
              <option value="">Выберите категорию</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Выберите изображение:</label>
            <input type="file" name="image" onChange={handleImageChange} />
          </div>
          <div>
            <label>Выберите PDF характеристики:</label>
            <input type="file" name="pdf" onChange={handlePdfChange} />
          </div>
          <div>
            <label>Отображать (да - галочка установлена):</label>
            <input
              type="checkbox"
              name="isActive"
              checked={formData.isActive}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <button type="submit">Сохранить изменения</button>
          </div>
        </form>
      )}
      {message && <div className={messageType}>{message}</div>}
    </div>
  );
};

export default UpdateProduct;
