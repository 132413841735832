import React from 'react';
import NotFoundBlock from '../Components/NotFoundBlock';

export default function NotFound() {
  return (
    <>
      <NotFoundBlock />
    </>
  );
}
