import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './AddAssortiment.module.scss';

export default function AddAssortiment() {
  const [formData, setFormData] = useState({
    nomenklaturoboznach: '',
    nominalnyytok: '',
    ispolnpoiznos: '',
    ispolnkontvspcep: '',
    voltageControlCircuit: '',
    stepzashity: '',
    klimatispoln: '',
    naznachnerorrev: '',
    krepleniyepuskatelya: '',
    sizeb: '',
    sizel: '',
    sizeh: '',
    massakg: '',
    productid: '',
    categoryid: '',
  });

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Загрузка списка товаров при монтировании компонента
    axios
      .get('https://server.puskateli.by/items-admin')
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  useEffect(() => {
    // Загрузка списка категорий при монтировании компонента
    axios
      .get('https://server.puskateli.by/categories')
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedProduct === '' || selectedCategory === '') {
      alert('Пожалуйста, выберите товар и категорию');
      return;
    }

    setLoading(true);

    try {
      await axios.post('https://server.puskateli.by/add-assortiment', formData);
      setSuccessMessage('Товар успешно добавлен');
    } catch (error) {
      console.error('Error adding assortiment:', error);
      alert('Ошибка при добавлении товара');
    } finally {
      setLoading(false);
      // Очищаем форму после успешной отправки
      // setFormData({
      // nomenklaturoboznach: '',
      // nominalnyytok: '',
      // ispolnpoiznos: '',
      // ispolnkontvspcep: '',
      // voltageControlCircuit: '',
      // stepzashity: '',
      // klimatispoln: '',
      // naznachnerorrev: '',
      // krepleniyepuskatelya: '',
      // sizeb: '',
      // sizel: '',
      // sizeh: '',
      // massakg: '',
      // productid: '',
      // categoryid: '',
      // });

      // setSelectedProduct('');
      // setSelectedCategory('');
    }
  };

  return (
    <div className={styles.upload_form}>
      <h2>Добавление товара</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Выберите товар:</label>
          <select
            name="productid"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}>
            <option value="">Выберите товар</option>
            {products.map((product) => (
              <option key={product.id} value={product.id}>
                {product.title}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Категория:</label>
          <select
            name="categoryid"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Выберите категорию</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        {selectedCategory && selectedProduct && (
          <div className={styles.additional_fields}>
            <div>
              <label>Номенклатурное обозначение:</label>
              <input
                type="text"
                name="nomenklaturoboznach"
                value={formData.nomenklaturoboznach}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Номинальный ток:</label>
              <input
                type="text"
                name="nominalnyytok"
                value={formData.nominalnyytok}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Исполнение по износостойкости:</label>
              <input
                type="text"
                name="ispolnpoiznos"
                value={formData.ispolnpoiznos}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Исполнение контактов вспомогательной цепи:</label>
              <input
                type="text"
                name="ispolnkontvspcep"
                value={formData.ispolnkontvspcep}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Исполнение по вольтажу цепи управления, ВАС:</label>
              <input
                type="text"
                name="voltageControlCircuit"
                value={formData.voltageControlCircuit}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Степень защиты:</label>
              <input
                type="text"
                name="stepzashity"
                value={formData.stepzashity}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Климатическое исполнение и размещение:</label>
              <input
                type="text"
                name="klimatispoln"
                value={formData.klimatispoln}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Назначение Нереверсивный (Н) / Реверсивный (Р):</label>
              <input
                type="text"
                name="naznachnerorrev"
                value={formData.naznachnerorrev}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Крепление пускателя:</label>
              <input
                type="text"
                name="krepleniyepuskatelya"
                value={formData.krepleniyepuskatelya}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Размеры, мм (B, L, H):</label>
              <input type="text" name="sizeb" value={formData.sizeb} onChange={handleInputChange} />
              <input type="text" name="sizel" value={formData.sizel} onChange={handleInputChange} />
              <input type="text" name="sizeh" value={formData.sizeh} onChange={handleInputChange} />
            </div>
            <div>
              <label>Масса, кг:</label>
              <input
                type="text"
                name="massakg"
                value={formData.massakg}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
        <div>
          <button type="submit" disabled={loading}>
            {loading ? 'Добавление...' : 'Добавить'}
          </button>
        </div>
        {successMessage && <div className={styles.success_message}>{successMessage}</div>}
      </form>
    </div>
  );
}
