import React from 'react';
import { Link } from 'react-router-dom';

import styles from './NotFoundBlock.module.scss';

export default function NotFoundBlock() {
  return (
    <div className={styles.root}>
      <h1>Ой: 404</h1>
      <h2>По вашему запросу ничего не найдено!</h2>
      <h3>Такой страницы не существует</h3>
      <Link to="/">
        <button className={styles.button}>Вернуться на главную страницу</button>
      </Link>
    </div>
  );
}
